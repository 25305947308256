import React from "react";
import imgpay from "../../assets/payment10.svg";
import "./Payment.css";
import { MdPayments } from "react-icons/md";
import {
  FaCoins,
  FaPlug,
  FaChartLine,
  FaHeadset,
  FaUserPlus,
  FaBitcoin,
  FaWallet,
} from "react-icons/fa";
import { Accordion } from "react-bootstrap";
import faq from "../../assets/svgg.svg";
import circle from "../../assets/circle.svg";
import line from "../../assets/line.svg";
import { useSelector } from "react-redux";

// Features Data (Accordion)
const Features = [
  {
    eventKey: "0",
    header: {
      en: "Security You Can Trust",
      ar: "أمان يمكنك الوثوق به",
    },
    body: {
      en: `
        At AHA Financing Broker, we understand that security is paramount in the world of digital finance. 
        Our payment services are built with the latest encryption technologies, ensuring that every transaction 
        is safe and secure. We employ advanced anti-fraud measures to protect both merchants and customers, 
        giving you peace of mind as you conduct your business.
      `,
      ar: `
        في AHA Financing Broker، ندرك أن الأمان هو الأهم في عالم التمويل الرقمي. 
        تم بناء خدمات الدفع لدينا باستخدام أحدث تقنيات التشفير، مما يضمن أن كل معاملة 
        آمنة ومأمونة. نستخدم إجراءات متقدمة لمكافحة الاحتيال لحماية كل من التجار والعملاء، 
        مما يمنحك راحة البال أثناء إدارة أعمالك.
      `,
    },
  },
  {
    eventKey: "1",
    header: {
      en: "Advanced Anti-Fraud Measures",
      ar: "إجراءات متقدمة لمكافحة الاحتيال",
    },
    body: {
      en: `
        Global expansion support, with reduced transaction costs and access to digital currencies.
      `,
      ar: `
        دعم التوسع العالمي، مع تقليل تكاليف المعاملات والوصول إلى العملات الرقمية.
      `,
    },
  },
  {
    eventKey: "2",
    header: {
      en: "Conversion Options",
      ar: "خيارات التحويل",
    },
    body: {
      en: `
        If desired, you can convert your crypto funds into traditional currencies 
        at competitive rates through our partner exchange services.
      `,
      ar: `
        إذا كنت ترغب في ذلك، يمكنك تحويل أموالك المشفرة إلى عملات تقليدية 
        بأسعار تنافسية من خلال خدمات التبادل التابعة لشركائنا.
      `,
    },
  },
];

// Cards Data (How It Works)
const cardsData = [
  {
    icon: <FaUserPlus className="display-4 text-primary" />,
    title: {
      en: "Set Up Your Account",
      ar: "قم بإعداد حسابك",
    },
    description: {
      en: "Sign up with AHA Financing Broker to create an account and gain access to our range of crypto payment solutions.",
      ar: "سجل في AHA Financing Broker لإنشاء حساب والحصول على وصول إلى مجموعة حلول الدفع بالعملات الرقمية لدينا.",
    },
  },
  {
    icon: <FaBitcoin className="display-4 text-grey" />,
    title: {
      en: "Choose Your Payment Method",
      ar: "اختر طريقة الدفع الخاصة بك",
    },
    description: {
      en: "Select from our available cryptocurrencies, including Bitcoin, Ethereum, and stablecoins, and integrate the chosen solution into your payment system.",
      ar: "اختر من بين العملات الرقمية المتاحة لدينا، بما في ذلك البيتكوين، الإيثريوم، والعملات المستقرة، وقم بدمج الحل المختار في نظام الدفع الخاص بك.",
    },
    yellowbg: true,
  },
  {
    icon: <FaWallet className="display-4 text-warning" />,
    title: {
      en: "Receive Payments",
      ar: "استقبل المدفوعات",
    },
    description: {
      en: "Once your setup is complete, you can begin accepting cryptocurrency payments immediately. The transactions are processed efficiently, with funds transferred directly to your digital wallet.",
      ar: "بمجرد اكتمال الإعداد، يمكنك البدء في قبول المدفوعات بالعملات الرقمية على الفور. تتم معالجة المعاملات بكفاءة، مع تحويل الأموال مباشرة إلى محفظتك الرقمية.",
    },
  },
];

// Features Data (Why Choose Crypto Payments)
const featuresData = [
  {
    icon: <FaCoins className="display-4 text-primary" />,
    title: {
      en: "Multi-Currency Support",
      ar: "دعم العملات المتعددة",
    },
    description: {
      en: "Accept a wide variety of cryptocurrencies, including Bitcoin, Ethereum, Litecoin, and others.",
      ar: "اقبل مجموعة واسعة من العملات الرقمية، بما في ذلك البيتكوين، الإيثريوم، اللايتكوين، وغيرها.",
    },
  },
  {
    icon: <FaPlug className="display-4 text-success" />,
    title: {
      en: "Seamless Integration",
      ar: "تكامل سلس",
    },
    description: {
      en: "Our solutions integrate effortlessly into your existing payment infrastructure, ensuring minimal disruption to your business operations.",
      ar: "تتكامل حلولنا بسهولة مع البنية التحتية للدفع الحالية لديك، مما يضمن الحد الأدنى من الاضطرابات في عمليات عملك.",
    },
  },
  {
    icon: <FaChartLine className="display-4 text-warning" />,
    title: {
      en: "Real-Time Monitoring",
      ar: "مراقبة في الوقت الحقيقي",
    },
    description: {
      en: "Track your transactions in real-time through our easy-to-use dashboard, giving you full visibility and control.",
      ar: "تابع معاملاتك في الوقت الحقيقي من خلال لوحة التحكم سهلة الاستخدام لدينا، مما يمنحك رؤية كاملة وتحكمًا كاملاً.",
    },
  },
  {
    icon: <FaHeadset className="display-4 text-danger" />,
    title: {
      en: "24/7 Support",
      ar: "دعم على مدار الساعة",
    },
    description: {
      en: "Our dedicated support team is available around the clock to assist with any questions or issues that may arise.",
      ar: "فريق الدعم المخصص لدينا متاح على مدار الساعة لمساعدتك في أي أسئلة أو مشكلات قد تظهر.",
    },
  },
];

// CardGrid Component
const CardGrid = ({ icon, title, description, col, yellowbg }) => (
  <div className={`col-12 col-md-6 ${col} mb-4`}>
    <div className={`card py-5 ${yellowbg && "bg-card-data"} border-0 h-100 shadow-sm`}>
      <div className="card-body">
        {icon}
        <h5 className="card-title py-3">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
    </div>
  </div>
);

// Payment Component
const Payment = () => {
  const isEnglish = useSelector((state) => state.language.isEnglish);
  const langDir = isEnglish ? "ltr" : "rtl"; // Set direction dynamically

  // Helper function to translate text
  const translate = (enText, arText) => (isEnglish ? enText : arText);

  return (
    <div dir={langDir} className="overflow-x-hidden">
      {/* Header Section */}
      <div className="row p-lg-5 p-2 mx-lg-3 mx-1 justify-content-center align-items-center">
        <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
          <img src={imgpay} className="w-75" alt="Crypto Payment" />
        </div>
        <div className="col-md-6 col-12">
          <span className="rounded-5 px-4 border border-4 text-bold font-900 fa-lg d-flex-inline align-items-center gap-1 text-gray font-bold py-1">
            <span>{translate("Crypto Payment", "الدفع عبر العملات الرقمية")}</span>
          </span>
          <h1 className="text-yellow py-3">
            {translate(
              "Crypto Payment Services at AHA Financing Broker",
              "خدمات الدفع عبر العملات الرقمية في AHA Financing Broker"
            )}
          </h1>
          <p>
            {translate(
              `At AHA Financing Broker, we are committed to providing secure,
              efficient, and seamless payment solutions to meet the growing
              demands of the digital world. Our Crypto Payment Services allow
              businesses and individuals to transact with ease using
              cryptocurrency. With the rise of digital currencies, we are
              dedicated to helping you navigate the evolving landscape of payments
              in a safe and trustworthy environment.`,
              `في AHA Financing Broker، نحن ملتزمون بتوفير حلول دفع آمنة وفعالة وسلسة لتلبية 
              الطلب المتزايد في عالم الرقمية. تتيح لك خدمات الدفع عبر العملات الرقمية لدينا إجراء 
              المعاملات بسهولة باستخدام العملات الرقمية. مع انتشار العملات الرقمية، نحن ملتزمون 
              بمساعدتك في التنقل عبر مشهد المدفوعات المتطور في بيئة آمنة وموثوقة.`
            )}
          </p>
        </div>
      </div>

      {/* How It Works Section */}
      <img src={line} alt="Line" />
      <div className="row p-lg-5 p-2 mx-lg-3 mx-1 justify-content-center align-items-center">
        <div className="col-12 text-center">
          <h1 className="text-black py-3">
            {translate("How It Works", "كيفية العمل")}
          </h1>
          <p>
            {translate(
              "Getting started with our crypto payment services is simple and straightforward:",
              "بدء استخدام خدمات الدفع عبر العملات الرقمية لدينا بسيط وسهل:"
            )}
          </p>
        </div>
        <div className="col-12 text-center">
          <div className="row justify-content-center align-items-center py-4">
            {cardsData.map((card, index) => (
              <CardGrid
                key={index}
                icon={card.icon}
                title={translate(card.title.en, card.title.ar)}
                description={translate(card.description.en, card.description.ar)}
                col={"col-lg-3"}
                yellowbg={card.yellowbg}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Why Choose Crypto Payments Section */}
      <img src={line} alt="Line" />
      <div className="row p-lg-5 p-2 mx-lg-3 mx-1 justify-content-center align-items-center">
        <div className="col-md-6">
          <div style={{ position: "relative" }}>
            <img
              src={circle}
              alt="Decorative Circle"
              style={{
                position: "absolute",
                top: -120,
                marginBlockEnd: 1,
                zIndex: -1,
                width: "220px",
                left: "-90px",
              }}
            />
            <div style={{ zIndex: 1 }}>
              <h1 className="text-black py-3">
                {translate("Why Choose Crypto Payments?", "لماذا تختار الدفع عبر العملات الرقمية؟")}
              </h1>
              <p>
                {translate(
                  `As digital currencies continue to gain popularity, more
                businesses are exploring the advantages of accepting crypto
                payments. Whether you’re looking to expand your payment options
                or streamline cross-border transactions, cryptocurrency offers
                numerous benefits.`,
                  `بينما تواصل العملات الرقمية اكتساب الشعبية، يستكشف المزيد
                من الشركات مزايا قبول المدفوعات عبر العملات الرقمية. سواء كنت تتطلع لتوسيع خيارات الدفع الخاصة بك
                أو تبسيط المعاملات عبر الحدود، تقدم العملات الرقمية العديد من الفوائد.`
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-center">
          <div className="row text-center py-4">
            {featuresData.map((feature, index) => (
              <CardGrid
                key={index}
                icon={feature.icon}
                title={translate(feature.title.en, feature.title.ar)}
                description={translate(feature.description.en, feature.description.ar)}
                col={"col-lg-6"}
              />
            ))}
          </div>
        </div>
      </div>

      {/* AHA Market Features Section */}
      <div className="row p-lg-5 p-2 bg-card-data align-items-center justify-content-center">
        <h1 className="text-black text-center py-3">
          {translate("AHA Market Features", "ميزات سوق AHA")}
        </h1>
        <div className="col-md-6 px-3 py-5">
          <Accordion>
            {Features.map((item, index) => (
              <Accordion.Item eventKey={item.eventKey} key={index}>
                <Accordion.Header>
                  {translate(item.header.en, item.header.ar)}
                </Accordion.Header>
                <Accordion.Body>
                  {translate(item.body.en, item.body.ar)
                    .split("\n")
                    .map((line, i) => (
                      <p key={i}>{line.trim()}</p>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>

      {/* Get Started Section */}
      <div className="row align-items-center py-5 text-center p-lg-5 p-2 bg-dark text-white justify-content-center">
        <h1 className="text-center text-white py-3">
          {translate("Get Started Today", "ابدأ اليوم")}
        </h1>
        <p className="px-5">
          {translate(
            `Are you ready to accept cryptocurrency payments and take your business to the next level? Whether you’re looking to expand globally, reduce transaction costs, or tap into the digital currency market, our Crypto Payment Services are designed to support your growth and success. 
            Contact us today to learn more or sign up for our services!`,
            `هل أنت مستعد لقبول المدفوعات عبر العملات الرقمية والنهوض بأعمالك إلى المستوى التالي؟ سواء كنت تتطلع للتوسع عالميًا، تقليل تكاليف المعاملات، أو دخول سوق العملات الرقمية، تم تصميم خدمات الدفع عبر العملات الرقمية لدينا لدعم نموك ونجاحك. 
            اتصل بنا اليوم لمعرفة المزيد أو للتسجيل في خدماتنا!`
          )}
        </p>
        <a href="https://bit.ly/ahamarket">
          <button className="btn btn-warning fs-5 fw-bold rounded-1 mt-3 px-5 py-2">
            {translate("Sign up", "سجل الأن")}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Payment;